
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class TotalOutstandingNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['suppliers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['suppliers', 'agents', 'dateAsOf'];

	public amount: number = 0;

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedSuppliers } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedSuppliers,
		]);
	}

	public async expensiveCalc() {
		let pa = 0.5;
		let pb = 0;

		const mmt = moment(this.selectedAsOfDate);

		const tradeRef = FilteredDatabase.ref('supplierTrades')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate);

		const pisdRef = FilteredDatabase.ref('supplierPiSds')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate);

		const koRef = FilteredDatabase.ref('supplierKos')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents)
			.numberRange('koDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			]);

		// Second task
		pb += pa;
		pa = 0.5;

		const pisdRefHead = await pisdRef.get();
		const koRefHead = await koRef.get();
		const tradeRefHead = await tradeRef.get();

		const gainLoss = await koRefHead.getSum('gainLoss');
		const ko = await koRefHead.getSum('amount');
		const pisd = await pisdRefHead.getSum('amount');
		const unappliedAmount = await tradeRefHead.getSum('unappliedAmount');

		this.amount = pisd - ko - gainLoss - unappliedAmount;

		this.saveHistory('amount');
	}
}
