
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import moment from 'moment';

@Component({
	components: {
		TableFilterable,
	},
})
export default class SupplierAgingOverviewTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['suppliers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['date', 'suppliers', 'agents'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
		return 'Supplier Aging Overview' + '_' + 'As Of ' + formattedDate;
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedSuppliers } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedSuppliers,
		]);
	}

	public async expensiveCalc() {
		const tradeRef = FilteredDatabase.ref('supplierTrades')
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate)
			.suppliers(this.selectedSuppliers);

		const pisdRef = FilteredDatabase.ref('supplierPiSds')
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate)
			.suppliers(this.selectedSuppliers);

		const koRef = FilteredDatabase.ref('supplierKos')
			.agents(this.selectedAgents)
			.suppliers(this.selectedSuppliers)
			.dateAsOf(this.selectedAsOfDate)
			.numberRange('koDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			]);

		let pa = 0.25;
		let pb = 0;

		const pisdDD = await this._loadDimensionByFilters(
			pisdRef,
			'suppliers',
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimensionByPeriod(reff, 'last30-120days', paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.25;

		const koDD = await this._loadDimensionByFilters(
			koRef,
			'suppliers',
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimensionByPeriod(reff, 'last30-120days', paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.25;

		const gainLossDD = await this._loadDimensionByFilters(
			koRef,
			'suppliers',
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimensionByPeriod(
					reff,
					'last30-120days',
					paa,
					pbb,
					'gainLoss',
				),
		);

		pb += pa;
		pa = 0.25;

		const tradeDD = await this._loadDimensionByFilters(
			tradeRef,
			'suppliers',
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimensionByPeriod(
					reff,
					'last30-120days',
					paa,
					pbb,
					'unappliedAmount',
				),
		);

		let periodMap: string[] = [];
		if (pisdDD[0]) {
			periodMap = pisdDD[0].subDimension!.map((subDD) => subDD.text);
		}

		this.tableFields = [
			{ key: 'company_name', sortable: true, stickyColumn: true },
			...periodMap.map((period) => ({
				key: period,
				// label: dd.text,
				sortable: true,
				formatter: TableItemFormatter.currency,
			})),
			{
				key: 'total',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
		];

		const items = pisdDD.map((dd, ddIndex) => {
			const koSub = koDD[ddIndex].subDimension;
			const gainLoassSub = gainLossDD[ddIndex].subDimension;
			const tradeSub = tradeDD[ddIndex].subDimension;

			const item: any = {
				total:
					dd.sum -
					koDD[ddIndex].sum -
					gainLossDD[ddIndex].sum -
					tradeDD[ddIndex].sum,
				company_name: dd.text,
			};
			dd.subDimension!.forEach((sd, sdIndex) => {
				item[sd.text] =
					sd.sum -
					koSub![sdIndex].sum -
					gainLoassSub![sdIndex].sum -
					tradeSub![sdIndex].sum;
			});
			return item;
		});

		this.tableItems = items.filter((item) => item.total !== 0);

		this.saveHistory('tableItems', 'tableFields');
	}
}
