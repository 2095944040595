
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PieChartFilterable from '@/components/reports-v2/components/filterables/PieChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { DimensionMap, PermissionsGroup } from '@/store/models.def';

@Component({
	components: {
		PieChartFilterable,
	},
})
export default class UnAppliedAmountPieChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['suppliers'];
	}

	public filterIds: Array<
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange', 'suppliers', 'agents'];

	public pieDatas: Array<{ name: string; value: number }> = [];

	public get expensiveHook() {
		const { selectedDateRange, selectedAgents, selectedSuppliers } = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedSuppliers,
		]);
	}

	public async expensiveCalc() {
		const codeList: string[] = ['PI', 'SD', 'SP', 'SC', 'ST'];

		const nameList: string[] = [
			'Invoice',
			'Debit Note',
			'Payment',
			'Credit Note',
			'Contra',
		];

		const map: DimensionMap[] = codeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'docType',
			value: [code],
			text: nameList[index],
		}));

		const ref = FilteredDatabase.ref('supplierTrades')
			.agents(this.selectedAgents)
			.suppliers(this.selectedSuppliers)
			.dateRange(this.selectedDateRange);

		const dimensionDatas = await this._loadDimension(
			ref,
			map,
			1,
			0,
			'unappliedAmount',
		);

		this.pieDatas = dimensionDatas.map((dd) => ({
			name: dd.text,
			value: dd.sum,
		}));

		// const jobId = (this.currentJobId = uuid.v4());

		// const amountList: number[] = [0, 0, 0, 0, 0, 0];

		// const pa = 1 / codeList.length;
		// let pb = 0;

		// // totalDue: ivdns, dueDate >= d1, dueDate <= d2
		// for (let i = 0; i < codeList.length; i++) {
		// 	const code = codeList[i];

		// 	const head = await FilteredDatabase.ref('customerTrades')
		// 		.agents(this.selectedAgents)
		// 		.customers(this.selectedCustomers)
		// 		.dateRange(this.selectedDateRange)
		// 		.includes('docType', [code])
		// 		.get();

		// 	this.generateLoadingText(pa * 0.5 + pb);
		// 	this.checkInterrupted(jobId);

		// 	const amount = await head.getSum('unappliedAmount');
		// 	this.generateLoadingText(pa + pb);
		// 	this.checkInterrupted(jobId);
		// 	pb += pa;

		// 	amountList[i] = amount;
		// }

		// this.pieDatas = nameList.map((name, index) => ({
		// 	name,
		// 	value: amountList[index],
		// }));
		this.saveHistory('pieDatas');
	}
}
