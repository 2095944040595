
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TreeMapFilterable from '@/components/reports-v2/components/filterables/TreeMapFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { DimensionData, PermissionsGroup } from '@/store/models.def';
import * as echarts from 'echarts';

@Component({
	components: {
		TreeMapFilterable,
	},
})
export default class SupplierOutstandingTreeMap extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['suppliers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['suppliers', 'agents', 'dateAsOf'];

	public result: any = [];
	public series: echarts.ECharts[] | any = [];

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedSuppliers } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedSuppliers,
		]);
	}

	public async expensiveCalc() {
		const tradeRef = FilteredDatabase.ref('supplierTrades')
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate)
			.suppliers(this.selectedSuppliers);

		const pisdRef = FilteredDatabase.ref('supplierPiSds')
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate)
			.suppliers(this.selectedSuppliers);

		const koRef = FilteredDatabase.ref('supplierKos')
			.agents(this.selectedAgents)
			.suppliers(this.selectedSuppliers)
			.numberRange('koDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			]);

		let pa = 0.25;
		let pb = 0;

		const pisdDD: DimensionData[] = await this._loadDimensionByFilters(
			pisdRef,
			'suppliers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.25;

		const koDD: DimensionData[] = await this._loadDimensionByFilters(
			koRef,
			'suppliers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.25;

		const gainLossDD: DimensionData[] = await this._loadDimensionByFilters(
			koRef,
			'suppliers',
			pa,
			pb,
			'gainLoss',
		);

		pb += pa;
		pa = 0.25;

		const unappliedAmountDD: DimensionData[] = await this._loadDimensionByFilters(
			tradeRef,
			'suppliers',
			pa,
			pb,
			'unappliedAmount',
		);

		this.result = [];

		pisdDD.forEach((pisd, index) => {
			this.result.push({
				name: pisd.text,
				value:
					pisd.sum -
					koDD[index].sum -
					gainLossDD[index].sum -
					unappliedAmountDD[index].sum,
			});
		});

		this.series = [];

		const stock = {
			name: 'Supplier Outstanding Amount',
			type: 'treemap',
			roam: 'move',
			width: '100%',
			drillDownIcon: '👆🏻',
			leafDepth: 1,
			label: {
				color: '#000',
			},
			itemStyle: {
				gapWidth: 1,
				borderWidth: 1,
			},
			data: this.result,
		};

		this.series = [stock];

		this.saveHistory('result', 'series');
	}
}
