
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup, SupplierPiSdCard } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import moment from 'moment';

@Component({
	components: {
		TableFilterable,
		SelectFilter,
	},
})
export default class SupplierUpcomingDue extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['suppliers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'duePeriod'
	> = ['suppliers', 'agents', 'dateAsOf', 'duePeriod'];

	public period: '14 days' | '30 days' | '60 days' = '30 days';
	public periodOptions = ['14 days', '30 days', '60 days'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
		return 'Supplier Upcoming Due' + '_' + 'As Of ' + formattedDate;
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get selectedDate() {
		const periodArgument = this.period.split(' ');
		const day = Number(periodArgument[0]);
		return day;
	}

	public get expensiveHook() {
		const {
			selectedDate,
			selectedSuppliers,
			selectedAsOfDate,
			selectedAgents,
		} = this;
		return JSON.stringify([
			selectedDate,
			selectedSuppliers,
			selectedAsOfDate,
			selectedAgents,
		]);
	}

	public async expensiveCalc() {
		const msInDay = 24 * 60 * 60 * 1000;

		const ref = FilteredDatabase.ref('supplierPiSds')
			.agents(this.selectedAgents)
			.suppliers(this.selectedSuppliers)
			.numberRange('dueDate', [
				['>=', this.selectedAsOfDate],
				['<=', this.selectedAsOfDate + this.selectedDate * msInDay],
			]);

		let pa = 0.5;
		let pb = 0;

		const cards: SupplierPiSdCard[] = await (await ref.get()).getCards();

		const filteredCards = cards.filter((item) => item.osAmount !== 0);

		pb += pa;
		pa = 0.45;

		this.tableFields = [
			{
				key: 'due_date',
				sortable: true,
				formatter: TableItemFormatter.date,
			},
			{
				key: 'doc_no',
				sortable: false,
			},
			{
				key: 'term',
				sortable: false,
			},
			{
				key: 'supplier',
				sortable: true,
			},
			{
				key: 'agent',
				sortable: true,
			},
			{
				key: 'outstanding_amount',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
		];

		const result = filteredCards.map((item) => {
			const object = {
				due_date: item.dueDate,
				doc_no: item.docNo,
				term: item.terms,
				supplier: gdbx.supplierNames[item.code],
				agent: gdbx.agentNames[item.agent],
				outstanding_amount: item.osAmount,
			};

			return object;
		});

		this.tableItems = result;

		this.saveHistory('tableItems', 'tableFields');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
