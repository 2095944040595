
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import StockListFilter from '@/components/reports-v2/components/filters/StockListFilter.vue';
import AgentsListFilter from '@/components/reports-v2/components/filters/AgentsListFilter.vue';
import SupplierListFilter from '@/components/reports-v2/components/filters/SupplierListFilter.vue';
// tslint:disable:max-line-length
import TotalOutstandingNumberDisplayer from '../../components/codedWidgets/supplier/TotalOutstandingNumberDisplayer.vue';
import TotalDueNumberDisplayer from '../../components/codedWidgets/supplier/TotalDueNumberDisplayer.vue';
import TotalOverdueNumberDisplayer from '../../components/codedWidgets/supplier/TotalOverdueNumberDisplayer.vue';
import SupplierOutstandingTreeMap from '../../components/codedWidgets/supplier/SupplierOutstandingTreeMap.vue';
import SupplierUpcomingDueTable from '../../components/codedWidgets/supplier/SupplierUpcomingDueTable.vue';
import UnappliedAmountPieChart from '../../components/codedWidgets/supplier/UnappliedAmountPieChart.vue';
import SupplierAgingSummaryBarChart from '../../components/codedWidgets/supplier/SupplierAgingSummaryBarChart.vue';
import SupplierAgingOverviewTable from '../../components/codedWidgets/supplier/SupplierAgingOverviewTable.vue';

@Component({
	components: {
		StickyFilterContainer,
		DateFilter,
		LastSync,
		SelectFilter,
		StockListFilter,
		AgentsListFilter,
		SupplierListFilter,
		TotalOutstandingNumberDisplayer,
		TotalDueNumberDisplayer,
		TotalOverdueNumberDisplayer,
		SupplierOutstandingTreeMap,
		SupplierUpcomingDueTable,
		UnappliedAmountPieChart,
		SupplierAgingOverviewTable,
		SupplierAgingSummaryBarChart,
	},
})
export default class SupplierOverview extends Vue {
	public selectedAgents: string[] = [];
	public selectedSuppliers: string[] = [];
	public selectedAsOfDate: number = moment().startOf('day').valueOf();

	public onDateAsOf(value: number) {
		this.selectedAsOfDate = value;
	}

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
