
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class TotalDueNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['suppliers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['suppliers', 'agents', 'dateAsOf'];

	public amount: number = 0;

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedSuppliers } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedSuppliers,
		]);
	}

	public async expensiveCalc() {
		const pisdRef = FilteredDatabase.ref('supplierPiSds')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents)
			.numberRange('dueDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			]);

		const koRef = FilteredDatabase.ref('supplierKos')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents)
			.numberRange('dueDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			])
			.numberRange('koDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			]);

		const unappliedAmountRef = FilteredDatabase.ref('supplierTrades')
			.customers(this.selectedCustomers)
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate);

		let pa = 0.7;
		let pb = 0;

		const pisdRefHead = await pisdRef.get();
		const koRefHead = await koRef.get();
		const unappliedAmountRefHead = await unappliedAmountRef.get();

		pb += pa;
		pa = 0.4;

		const gainLoss = await koRefHead.getSum('gainLoss');
		const ko = await koRefHead.getSum('amount');
		const pisd = await pisdRefHead.getSum('amount');
		const unappliedAmount = await unappliedAmountRefHead.getSum(
			'unappliedAmount',
		);

		this.generateLoadingText(1);

		this.amount = pisd - ko - gainLoss - unappliedAmount;

		this.saveHistory('amount');
	}
}
