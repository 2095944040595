
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { roundToFixed } from '@/util/number';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class TotalOverdueNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['suppliers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['suppliers', 'agents', 'dateAsOf'];

	public amount: number = 0;

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedSuppliers } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedSuppliers,
		]);
	}

	public async expensiveCalc() {
		const pisdRef = FilteredDatabase.ref('supplierPiSds')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents);

		const asOfPisdRef = pisdRef.clone().dateAsOf(this.selectedAsOfDate);
		const duePisdRef = asOfPisdRef.clone().numberRange('dueDate', [
			['>', Number.NEGATIVE_INFINITY],
			['<=', this.selectedAsOfDate],
		]);

		const koRef = FilteredDatabase.ref('supplierKos')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents);

		const asOfKoRef = koRef.clone().numberRange('koDate', [
			['>', Number.NEGATIVE_INFINITY],
			['<=', this.selectedAsOfDate],
		]);
		const dueKoRef = asOfKoRef.clone().numberRange('dueDate', [
			['>', Number.NEGATIVE_INFINITY],
			['<=', this.selectedAsOfDate],
		]);

		const tradeRef = FilteredDatabase.ref('supplierTrades')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents);
		const asOfTradeRef = tradeRef.clone().dateAsOf(this.selectedAsOfDate);

		let pa = 0.45;
		let pb = 0;

		const pisd = await (await asOfPisdRef.get()).getSum('amount');
		const ko = await (await asOfKoRef.get()).getSum('amount');
		const gainLoss = await (await asOfKoRef.get()).getSum('gainLoss');
		const unappliedAmount = await (await asOfTradeRef.get()).getSum(
			'unappliedAmount',
		);

		pb += pa;
		pa = 0.45;

		const duePisd = await (await duePisdRef.get()).getSum('amount');
		const dueKo = await (await dueKoRef.get()).getSum('amount');
		const dueGainLoss = await (await dueKoRef.get()).getSum('gainLoss');

		const totalOutstanding = pisd - ko - gainLoss - unappliedAmount;
		const totalDue = duePisd - dueKo - dueGainLoss - unappliedAmount;

		this.generateLoadingText(1);

		this.amount =
			totalOutstanding !== 0
				? roundToFixed((totalDue / totalOutstanding) * 100, gdbx.numDecimal)
				: 0;

		this.saveHistory('amount');
	}
}
